import { DeleteOutlined, DownloadOutlined, FileImageOutlined, FileOutlined, LinkOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Dropdown, Image, message, notification, Space, Tooltip } from 'antd'
import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

import { formatTime } from '@/common/utils'

import { deleteFile } from '../reducers/assetsReducers'

type Props = {
  id: string
  name: string
  type: string
  url: string
  createAt: string
}

const ItemsCardUpload: React.FC<Props> = ({ id, name, type, url, createAt }: Props) => {
  const dispatch = useDispatch<any>()
  const [messageApi, contextHolder] = message.useMessage()

  const handleDeleteFile = async () => {
    try {
      await dispatch(deleteFile({ id }))
      notification.success({
        message: 'Deleted file successfully',
        description: `File ${name} has been deleted.`,
        placement: 'bottomRight'
      })
    } catch (error) {
      notification.error({
        message: 'Delete file failed',
        description: `Failed to delete file ${name}.`,
        placement: 'bottomRight'
      })
    }
  }

  const handleDownloadImage = async () => {
    try {
      const response = await fetch(url)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()

      URL.revokeObjectURL(link.href)
    } catch (error) {
      notification.warning({
        message: 'Download image failed',
        description: `Image  cannot be downloaded`,
        placement: 'bottomRight'
      })
    }
  }

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url)
    messageApi.success('Copied URL to clipboard!')
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <div className='flex gap-1 text-sm'>
          <LinkOutlined />
          <span>Copy Url</span>
        </div>
      ),
      key: '0',
      onClick: handleCopyUrl
    },
    {
      label: (
        <div className='flex gap-1 text-sm'>
          <DownloadOutlined />
          <span>Download</span>
        </div>
      ),
      key: '1',
      onClick: handleDownloadImage
    },
    {
      onClick: handleDeleteFile,
      label: (
        <div className='flex gap-1 text-sm'>
          <DeleteOutlined />
          <span>Delete</span>
        </div>
      ),
      key: '2'
    }
  ]

  return (
    <>
      {contextHolder}
      <div className='w-full cursor-pointer overflow-hidden rounded-lg bg-gray-100 p-1 shadow hover:shadow-md'>
        <div className='relative gap-2 p-2'>
          <div className='flex w-[90%] flex-row items-center gap-1 whitespace-nowrap text-sm'>
            <span>{type === 'image' ? <FileImageOutlined /> : <FileOutlined />}</span>
            <span className='text-sm'>
              {name.length > 14 ? (
                <Tooltip placement='topRight' title={name}>
                  {name.slice(0, 14)}...
                </Tooltip>
              ) : (
                name
              )}
            </span>
          </div>

          <div className='absolute right-0 top-2 w-[10%]  bg-gray-100 pr-1'>
            <Dropdown menu={{ items }} trigger={['click']}>
              <div onClick={(e) => e.preventDefault()}>
                <Space>
                  <BsThreeDotsVertical />
                </Space>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className='m-0 w-full p-1'>
          <div className='flex h-[150px] w-full items-center justify-center overflow-hidden'>
            {type === 'image' ? (
              <Image className='h-full w-full rounded object-contain' src={url} />
            ) : (
              <div className='flex w-full items-center justify-center opacity-60'>
                <FileOutlined className='text-[100px]' />
              </div>
            )}
          </div>
        </div>
        <span className='px-1 text-xs'>Date: {formatTime(new Date(createAt))}</span>
      </div>
    </>
  )
}

export default ItemsCardUpload
