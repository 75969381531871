import { DownloadOutlined, FileOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Button, Empty, Flex, Image, Input, message, Pagination, Popconfirm, Select, Space, Spin, Table, Tooltip } from 'antd'
import type { TableRowSelection } from 'antd/es/table/interface'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { BsGrid, BsList } from 'react-icons/bs'
import { IoIosSearch } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { formatTime } from '@/common/utils'
import type { RootState } from '@/store'

import ItemsCardUpload from './components/ItemsCardUpload'
import UploadFile from './components/UploadFile'
import { deleteFile, getAssetsByProjectId, setIsGridAllowed, updateFilter } from './reducers/assetsReducers'

interface DataType {
  id: string | number
  name: string
  apiKey: string
  userId: number
  createdAt: string
}

const ProjectAssets = () => {
  const params = useParams()
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const listAssetsProject = useSelector((state: RootState) => state.assetsProjects)
  const [valueSearch, setValueSearch] = useState(listAssetsProject.filter.search || '')
  const typeGrid = useSelector((state: RootState) => state.typeGridAssets.isGridAssetsAllowed)
  const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  /**
   * ----------- Actions --------------
   */

  const handleGetAssetByProjectId = async () => {
    setLoading(true)
    try {
      if (params.id) {
        await dispatch(getAssetsByProjectId({ projectId: params.id })).unwrap()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onChangePage = async (page: number, pageSize?: number) => {
    const newFilter = {
      ...listAssetsProject.filter,
      page,
      ...(pageSize && { limit: pageSize })
    }
    await dispatch(updateFilter(newFilter))
    await handleGetAssetByProjectId()
  }

  const handleChangeSort = async (value: string) => {
    let newFilter = {}
    newFilter = { ...listAssetsProject.filter, sorts: value }
    await dispatch(updateFilter(newFilter))
    await handleGetAssetByProjectId()
  }

  const handleDeleteFile = async (id: string, name: string, notify = true) => {
    try {
      await dispatch(deleteFile({ id }))
      if (notify) {
        messageApi.success(`File ${name} has been deleted`)
      }
    } catch (error) {
      if (notify) {
        messageApi.error(`File ${name} cannot be deleted`)
      }
    }
  }

  const handleDownloadImage = async (data: any) => {
    try {
      const imageUrl = data.url
      const imageName = data.originalname
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = imageName
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (error) {
      messageApi.warning(`Image cannot be downloaded`)
    }
  }

  const delteFileSelected = async () => {
    setLoading(true)
    const count = selectedRowKeys.length
    try {
      if (selectedRowKeys.length > 0) {
        selectedRowKeys.forEach((id) => {
          const file = listAssetsProject.items.find((item: any) => item.id === id)
          if (file) {
            handleDeleteFile(file.id, file.originalname, false)
          }
        })
      }
    } catch (error) {
      setLoading(false)
      messageApi.error(`Delete ${count} file failed`)
    } finally {
      messageApi.success(`Delete ${count} file successfully`)
      setTimeout(() => {
        setSelectedRowKeys([])
        setLoading(false)
      }, 1000)
    }
  }

  useEffect(() => {
    if (params.id) {
      handleGetAssetByProjectId()
    }
  }, [params.id])

  /**
   *  handle table selection
   */

  const handleShowAssets = () => {
    dispatch(setIsGridAllowed(!typeGrid))
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const selectFileDelete: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      width: '44%',
      render: (data: any) => {
        const type = data?.type
        const url = data?.url
        const filename = data?.originalname
        if (type === 'raw') {
          return (
            <div className='flex flex-row items-center gap-2 '>
              <div className='w-max rounded bg-gray-200 p-2'>
                <FileOutlined className='text-5xl text-gray-500' />
              </div>
              <span>{filename}</span>
            </div>
          )
        }
        return (
          <div className=' flex flex-row items-center gap-2'>
            <div className='w-max rounded bg-gray-200 p-2'>
              <Image className='h-[50px] w-[50px] rounded object-cover' src={url} alt={filename} />
            </div>
            <span>{filename}</span>
          </div>
        )
      }
    },
    {
      title: 'Url',
      width: '5%',
      dataIndex: 'url',
      key: 'size',
      render: (val: string) => (
        <Tooltip placement='top' title='Copy Url'>
          <LinkOutlined
            onClick={() => {
              navigator.clipboard.writeText(val)
              messageApi.success('Copied URL to clipboard!')
            }}
          />
        </Tooltip>
      )
    },
    {
      title: 'Type',
      width: '20%',
      dataIndex: 'mimetype',
      key: 'mimetype'
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: '15%',
      render: (val: string) => <div>{formatTime(new Date(val))}</div>
    },
    {
      title: 'Action',
      fixed: 'right',
      width: '10%',
      render: (data: any) => (
        <div className='flex w-full justify-center gap-3'>
          <Button onClick={() => handleDownloadImage(data)} type='primary'>
            <DownloadOutlined />
          </Button>
        </div>
      )
    }
  ]

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(updateFilter({ search: text }))
      handleGetAssetByProjectId()
    }, 500),
    []
  )

  const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true)
      const text = e.target.value
      setValueSearch(text)
      handleSearch(text)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const showUpload = () => {
    setShowUploadFiles(!showUploadFiles)
  }

  return (
    <div>
      {contextHolder}
      <div className='relative flex w-full flex-col gap-5'>
        <div className='flex flex-col gap-2'>
          <Button
            onClick={showUpload}
            type='primary'
            aria-multiline
            icon={<UploadOutlined />}
            className='w-max text-base'
            aria-label='Upload File'
            title='Upload File'
          >
            Upload Files
          </Button>
          {showUploadFiles && <UploadFile />}
        </div>

        <div className='flex flex-row items-center justify-between'>
          <div className='flex flex-row gap-2'>
            <Button className='px-5' onClick={handleShowAssets} icon={<BsGrid size={18} />} type={!typeGrid ? 'primary' : 'default'} />
            <Button className='px-5' onClick={handleShowAssets} icon={<BsList size={18} />} type={typeGrid ? 'primary' : 'default'} />
          </div>
          <div className='flex flex-row justify-end'>
            <div className='flex flex-row gap-4'>
              <Space wrap>
                <div>
                  <div>
                    <span>Sort by: </span>
                    <Select
                      className='h-10 w-[120px]'
                      defaultValue='createdAt'
                      onChange={handleChangeSort}
                      value={listAssetsProject.filter.sorts}
                      options={[
                        { value: '-createdAt', label: 'Latest' },
                        { value: 'createdAt', label: 'Oldest' }
                      ]}
                    />
                  </div>
                </div>
              </Space>
              <Input
                placeholder='Please enter search'
                value={valueSearch}
                onChange={handleChangeInputSearch}
                className='w-full px-[11px] py-2 lg:w-[300px]'
                suffix={<IoIosSearch />}
              />
            </div>
          </div>
        </div>

        {!typeGrid ? (
          <div>
            {!loading ? (
              <div className='z-0'>
                {listAssetsProject && listAssetsProject.items.length > 0 ? (
                  <div>
                    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5'>
                      {listAssetsProject.items.map((file: any) => (
                        <ItemsCardUpload key={file.id} id={file.id} name={file.originalname} createAt={file.createdAt} type={file.type} url={file.url} />
                      ))}
                    </div>
                    <div className='flex w-full justify-center p-5'>
                      <Pagination
                        onChange={onChangePage}
                        showSizeChanger
                        defaultCurrent={listAssetsProject.filter.page}
                        total={listAssetsProject.total}
                        pageSize={listAssetsProject.filter.limit}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='flex w-full justify-center'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </div>
            ) : (
              <div className='flex w-full justify-center'>
                <Spin />
              </div>
            )}
          </div>
        ) : (
          <div>
            <Flex gap='middle' vertical>
              <Flex align='center' gap='middle'>
                <Popconfirm
                  onConfirm={delteFileSelected}
                  title='Delete file?'
                  description='Are you sure you want to delete the selected files?'
                  okText='Yes'
                  cancelText='No'
                >
                  <Button type='primary' disabled={!selectedRowKeys.length} loading={loading}>
                    Delete
                  </Button>
                </Popconfirm>
                {selectedRowKeys.length ? `Selected ${selectedRowKeys.length} items` : ''}
              </Flex>
              <Table
                rowSelection={selectFileDelete}
                columns={columns}
                rowKey='id'
                rowClassName='cursor-pointer'
                dataSource={listAssetsProject.items}
                loading={loading}
                showSorterTooltip={false}
                pagination={{ pageSize: listAssetsProject.filter.limit, current: listAssetsProject.filter.page, total: listAssetsProject.total }}
              />
            </Flex>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProjectAssets
